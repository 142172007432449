import { useMemo, useRef } from 'react'
import { Form } from 'formular'
import localStorage from 'local-storage'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import {
  canadaPostalCode,
  maxLength,
  minLength,
  nonMilitaryState,
  nonMilitaryZipCode,
  phone,
  required,
  streetAddress,
  ukPostalCode,
  userName,
  conditional,
  zipCode,
} from 'helpers/validators'


const postalCodeValidator = conditional<UserModule.ShippingFormFields>((values) => {
  if (values.country === 'US') {
    return [ required, zipCode, nonMilitaryZipCode ]
  }

  if (values.country === 'CA') {
    return [ required, canadaPostalCode ]
  }

  if (values.country === 'GB') {
    return [ required, ukPostalCode ]
  }

  return []
})

const regionValidator = conditional<UserModule.ShippingFormFields>((values) => {
  if (values.country === 'US') {
    return [ required, nonMilitaryState ]
  }

  if (values.country === 'CA') {
    return [ required ]
  }

  if (values.country === 'GB') {
    // region is not required for UK
    return []
  }

  return []
})

type UseShippingFormProps = {
  initialValues?: Partial<UserModule.ShippingFormFields>
  useLocalStorage?: boolean
}

const useShippingForm = ({ initialValues, useLocalStorage = true }: UseShippingFormProps = {}) => {
  // to keep it unchanged
  const initialValuesRef = useRef(initialValues)
  const isAttentivePhonePermissionsEnabled = useFeatureIsOn(constants.features.attentiveCollectPhoneNumber)

  return useMemo(() => {
    const initialValuesFromLocalStorage = __CLIENT__ && useLocalStorage ? localStorage.getItem<UserModule.ShippingFormFields>(constants.localStorageNames.shippingFormValues) : null
    const initialValues: Partial<UserModule.ShippingFormFields> = initialValuesRef.current || initialValuesFromLocalStorage || {}

    // we apply zip code for the USA and postal code for other countries
    const shippingForm = new Form<UserModule.ShippingFormFields>({
      fields: {
        firstName: [ required, userName, maxLength(100) ],
        lastName: [ required, userName, maxLength(100) ],
        street1: [ required, streetAddress ],
        street2: [],
        postalCode: [ postalCodeValidator ],
        city: [ required, minLength(3) ],
        region: [ regionValidator ],
        country: {
          validate: [ required ],
          value: 'US',
        },
        phone: [
          phone,
          conditional<UserModule.ShippingFormFields>(
            (values) => values.receiveAlertsByPhone || values.receiveOffersByPhone ? [ required ] : []
          ),
        ],
        receiveAlertsByPhone: [],
        receiveOffersByPhone: [],
      },
      getValues: (values) => {
        const additionalFields = {
          receiveAlertsByPhone: isAttentivePhonePermissionsEnabled && Boolean(values.receiveAlertsByPhone),
          receiveOffersByPhone: isAttentivePhonePermissionsEnabled && Boolean(values.receiveOffersByPhone),
        }

        // we add to values only true values for better tests support
        Object.keys(additionalFields).forEach((key) => {
          if (additionalFields[key]) {
            values[key] = additionalFields[key]
          }
          else {
            delete values[key]
          }
        })

        return values
      },
      initialValues,
    })

    // ATTN we should do it only on client side
    if (__CLIENT__) {
      let lastCountryValue = initialValues.country || 'US'

      // change postal code validators based when user selects a country
      shippingForm.fields.country.on('change', (newCountryValue) => {
        if (newCountryValue !== lastCountryValue) {
          lastCountryValue = newCountryValue

          const fieldsToReset = [
            shippingForm.fields.region,
            shippingForm.fields.city,
            shippingForm.fields.street1,
            shippingForm.fields.street2,
            shippingForm.fields.postalCode,
          ]

          fieldsToReset.forEach((field) => field.set(''))
        }
      })

      // save values to local storage
      if (useLocalStorage) {
        shippingForm.on('change', () => {
          const values = shippingForm.getValues()

          localStorage.setItem(constants.localStorageNames.shippingFormValues, values)
        })
      }
    }

    return shippingForm
  }, [ useLocalStorage, isAttentivePhonePermissionsEnabled ])
}


export default useShippingForm
